import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelfContacts'])
  },
  methods: {
    ...mapActions('fund', ['linkAccount']),
    ...mapActions('contact', ['listSelfContacts', 'archiveContact']),
    onSuccess(publicToken, metadata) {
      const loader = this.showLoader();
      this.linkAccount({
        accountId: this.getSelectedAccount.id,
        plaidToken: publicToken,
        plaidAccountId: metadata.account.id
      })
        .then(() => {
          this.fetchContactsWithTimer();
        })
        .catch((e) => {
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    onEvent() {
    },
    fetchContacts() {
      const loader = this.showLoader();
      this.listSelfContacts(this.getSelectedAccount.id)
        .catch((e) => {
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    removeContact(id) {
      const loader = this.showLoader();
      this.archiveContact(id)
        .then(() => {
          this.fetchContactsWithTimer();
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    fetchContactsWithTimer() {
      setTimeout(() => {
        this.fetchContacts();
      }, 0);
    }
  }
};
